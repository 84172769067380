<template>
	<!--开发-测试用例 -->
  <div class="container" style="padding: 20px;">
    <GModule>
		<div class="condition-box">
			<div class="state-box">
				<p>状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem.title}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{stateIndex ? stateArr[stateIndex].title : '全部'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>用例类型</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick2">
							<a-menu-item v-for="(nitem,nindex) in nstatusArr" :key="nindex">{{nitem.title}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{nstatusIndex ? nstatusArr[nstatusIndex].title : '全部'}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<a-button class="btn clo1">导出</a-button>
		</div>
		<a-table 
			class="ttable" 
			sticky 
			:columns="columnsHe" 
			:data-source="data" 
			:scroll="{ x: 500,y:250 }" 
			bordered
			:pagination="false"
			:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
		  <template #bodyCell="{ column ,record}">
				<template v-if="column.title === '用例ID'">
					<div class="txt">
						<p class="red"></p>
						<p>002</p>
					</div>
				</template>
				<template v-if="column.key === 'operation'">
					<div class="caozuo">
						<p>完成测试</p>
						<p>详情</p>
					</div>
				</template>
		  </template>
		</a-table>
		<div class="documentFt">
		  <a-pagination size="small" :total="50" />
		</div>
    </GModule>
  </div>
</template>
<script>
export default {
  name: 'TaskProjectTestCase',
  data(){
    return {
	    productArr:[
		    {title:"测试用例"},
	    ], // 需求tab标签页
		activeKey:0,
		stateArr:[
			{title:"全部"},
		    {title:"待审核"},
		    {title:"审核未通过"},
		    {title:"待测"},
			{title:"已测"},
	    ] ,// 状态列表
		stateIndex:0,
		manArr:[
			{title:"全部"},
		    {title:"待测"},
		    {title:"已测"}
		] ,// 状态列表
		manIndex:0,
		nstatusArr:[
			{title:"全部"},
		    {title:"主流程用例"},
		    {title:"全量测试用例"}
		] ,// 创建时间列表
		nstatusIndex:0,
		createTimeArr:[
			{title:"今天"},
		    {title:"本周"},
		    {title:"本月"},
		    {title:"一个月前"}
		] ,// 创建时间列表
		createTimeIndex:0,
		columnsHe: [{
		    title: '用例ID',
		    width: 80,
		    dataIndex: 'index',
		    key: 'index',
		    fixed: 'left',
		  }, {
		    title: '用例类型',
		    width: 100,
		    dataIndex: 'name',
		    key: 'describe',
		    fixed: 'left',
		  }, {
		    title: '用例摘要',
		    width: 120,
		    dataIndex: 'image',
		    key: 'image'
		  }, {
		    title: '前置条件',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '操作步骤',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '预期结果',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  }, {
		    title: '状态',
		    width: 100,
		    dataIndex: 'age',
		    key: 'age'
		  },{
		    title: '操作',
		    key: 'operation',
		    fixed: 'right',
		    width: 180,
		}],
		data: [{
		  key: '1',
		  name: 'John Brown',
		  age: 32,
		  address: 'New York No. 1 Lake Park',
		}, {
		  key: '2',
		  name: 'Jim Green大家聚聚军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军军',
		  age: 42,
		  address: 'London No. 1 Lake Park',
		}, {
		  key: '3',
		  name: 'Joe Black',
		  age: 32,
		  images:[],
		  address: 'Sidney No. 1 Lake Park',
		}, {
		  key: '4',
		  name: 'Jim Red',
		  age: 32,
		  address: 'London No. 2 Lake Park',
		},{
		  key: '5',
		  name: 'John Brown',
		  age: 32,
		  address: 'New York No. 1 Lake Park',
		}, {
		  key: '6',
		  name: 'Jim Green',
		  age: 42,
		  address: 'London No. 1 Lake Park',
		}, {
		  key: '7',
		  name: 'Joe Black',
		  age: 32,
		  address: 'Sidney No. 1 Lake Park',
		}, {
		  key: '8',
		  name: 'Jim Red',
		  age: 32,
		  address: 'London No. 2 Lake Park',
		}],
		value1:'这双'
    }
  },
  methods:{
	  handleMenuClick(e){
		  this.stateIndex = e.key
		  console.log("选择",this.stateIndex)
	  },
	  handleManClick(e){
		  this.manIndex = e.key
		  console.log("选择",this.manIndex)
	  },
	  handleMenuClick2(e){
		  this.nstatusIndex = e.key
		  console.log("选择",this.nstatusIndex)
	  },
	  handleCreatTime(e){
		  this.createTimeIndex = e.key
		  console.log("选择",this.createTimeIndex)
	  }
  }

}
</script>

<style scoped lang="scss">
.product{
	display: flex;
	.add{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		.ant-btn-icon-only{
			width: 40px !important;
			height: 40px !important;
			padding:0 !important;
		}
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	justify-content: space-around;
	align-items: center;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
	}
}


</style>